<template>
  <div class="highlights-tower">
    <template v-for="highlightBlock in highlightsTowerData">
      <HighlightDocumentBlock
        v-if="highlightBlock.documents"
        :key="highlightBlock.id"
        class="highlight-block-element"
        :highlight-block="highlightBlock"
      />
      <HighlightVideoBlock
        v-else-if="highlightBlock.videoUrl"
        :key="highlightBlock.id"
        class="highlight-block-element"
        :highlight-block="highlightBlock"
      />
      <HighlightBlock
        v-else
        :key="highlightBlock.id"
        class="highlight-block-element"
        :highlight-block="highlightBlock"
      />
    </template>
  </div>
</template>

<script>
import HighlightBlock from '@/components/HighlightsTower/HighlightBlock'
import HighlightVideoBlock from '@/components/HighlightsTower/HighlightVideoBlock'
import HighlightDocumentBlock from '@/components/HighlightsTower/HighlightDocumentBlock'

export default {
  name: 'HighlightsTowerContainer',
  components: { HighlightDocumentBlock, HighlightVideoBlock, HighlightBlock },
  props: {
    highlightsTowerData: {
      type: Array,
      required: false,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
.highlights-tower {
  @apply flex flex-col;

  .highlight-block-element {
    &:nth-of-type(odd) {
      box-shadow: 0 0 25px rgba(82, 94, 110, 62%);
      z-index: 10 !important;
    }
    &:nth-of-type(even) {
      z-index: 9 !important;
    }
  }
}
</style>
