import helpers from './helpers/helpers'

export default {
  loadFourZeroFourPage() {
    const requestData = {
      query: `{
        entry(slug: "404") {
          title
          ... on fourZeroFour_fourZeroFour_Entry{
            seo {
              title
            }
            plainText
          }
        }
      }`
    }

    return helpers.makeContentRequest(requestData, '404')
  },
  loadSearchPage() {
    const requestData = {
      query: `{
        entry(slug: "search") {
          title
          ...on search_search_Entry {
            seo {
              title
            }
          }
        }
      }`
    }

    return helpers.makeContentRequest(requestData, 'search')
  },
  searchContent(keywords) {
    const requestData = {
      query: `{
        entries(search: "${keywords}", orderBy: "score") {
          id
          title
          url
          uri
          seo {
            title
            description
          }
        }
      }`
    }

    return helpers.makeContentRequest(
      requestData,
      'search.' + helpers.slugify(keywords)
    )
  }
}
