import helpers from './helpers/helpers'

export default {
  loadMilneStoryPage() {
    const requestData = {
      query: `{
        entry(slug: "milne-story") {
          ... on milneStory_milneStory_Entry {
            seo {
              title
            }
            storyHero {
              ... on storyHero_Hero_BlockType {
                backgroundImage {
                  url
                }
                titleText
                videoUrl
                subtitle
              }
            }
            highlightsTower {
              ... on highlightsTower_towerBlock_BlockType {
                id
                imagePosition
                image {
                  url
                  title
                }
                contentPosition
                theme
                contentWhiteBackground
                blockTitle
                text
                buttonText
                buttonLink
              }
              ... on highlightsTower_towerVideo_BlockType {
                id
                backgroundImage {
                  url
                }
                videoUrl
              }
            }
            paragraphBackgroundImage {
              ... on paragraphBackgroundImage_container_BlockType {
                backgroundImage {
                  url
                }
                paragraph
              }
            }
            backgroundImage {
              id
              url
              title
            }
          }
        }
      }`
    }

    return helpers.makeContentRequest(requestData, 'milneStory')
  }
}
