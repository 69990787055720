import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/store'

// Views
import Home from '@/views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    alias: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/fruits-and-vegetables',
    name: 'FruitsAndVegetables',
    // component: FruitsAndVegetables
    // route level code-splitting
    // this generates a separate chunk (fruitAndVegetables.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "fruitAndVegetables" */
        '../views/FruitsAndVegetables.vue'
      )
  },
  {
    path: '/fruits-and-vegetables/:productSlug',
    name: 'FruitsAndVegetablesProduct',
    // component: FruitsAndVegetablesProduct
    component: () =>
      import(
        /* webpackChunkName: "fruitAndVegetablesProduct" */
        '../views/Product.vue'
      )
  },
  {
    path: '/pet-food',
    name: 'PetFood',
    // component: PetFood
    component: () =>
      import(
        /* webpackChunkName: "petFood" */
        '../views/PetFood.vue'
      )
  },
  {
    path: '/brewing',
    name: 'Brewing',
    // component: Brewing
    component: () =>
      import(
        /* webpackChunkName: "brewing" */
        '../views/Brewing.vue'
      )
  },
  {
    path: '/custom-solutions',
    name: 'CustomSolutions',
    // component: CustomSolutions
    component: () =>
      import(
        /* webpackChunkName: "customSolutions" */
        '../views/CustomSolutions.vue'
      )
  },
  {
    path: '/certifications',
    name: 'Certifications',
    // component: Certifications
    component: () =>
      import(
        /* webpackChunkName: "certificationLocations" */
        '../views/Certifications.vue'
      )
  },
  {
    path: '/certifications/:locationSlug',
    name: 'LocationCertifications',
    // component: LocationCertifications
    component: () =>
      import(
        /* webpackChunkName: "LocationCertifications" */
        '../views/LocationCertifications.vue'
      )
  },
  {
    path: '/contact',
    name: 'Contact',
    // component: Contact
    component: () =>
      import(
        /* webpackChunkName: "contact" */
        '../views/Contact.vue'
      )
  },
  {
    path: '/milne-story',
    name: 'MilneStory',
    // component: MilneStory
    component: () =>
      import(
        /* webpackChunkName: "milneStory" */
        '../views/MilneStory.vue'
      )
  },
  {
    path: '/search',
    name: 'Search',
    // component: Search
    component: () =>
      import(
        /* webpackChunkName: "search" */
        '../views/Search.vue'
      )
  },
  {
    path: '/404',
    name: 'FourOhFour',
    // component: FourOhFour
    component: () =>
      import(
        /* webpackChunkName: "fourOhFour" */
        '../views/FourOhFour.vue'
      )
  },
  {
    path: '/calculator',
    name: 'Calculator',
    // component: FourOhFour
    component: () =>
      import(
        /* webpackChunkName: "fourOhFour" */
        '../views/Calculator.vue'
      )
  },
  {
    path: '/:genericPageSlug',
    name: 'GenericPage',
    // component: GenericPage
    component: () =>
      import(
        /* webpackChunkName: "fourOhFour" */
        '../views/GenericPage.vue'
      )
  },
  {
    path: '*',
    redirect: { name: 'FourOhFour' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  store._modules.root._children.requests.context.dispatch(
    'CANCEL_PENDING_REQUESTS'
  )
  next()
})

export default router
