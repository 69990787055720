import base from './base'
import brewing from './brewing'
import calculator from './calculator'
import certifications from './certifications'
import contact from './contact'
import customSolutions from './customSolutions'
import fruitsAndVegetables from './fruitsAndVegetables'
import genericPage from './genericPage'
import globals from './globals'
import home from './home'
import milneStory from './milneStory'
import petFood from './petFood'

export default {
  base,
  brewing,
  calculator,
  certifications,
  contact,
  customSolutions,
  fruitsAndVegetables,
  genericPage,
  globals,
  home,
  milneStory,
  petFood
}
