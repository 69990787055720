require('intersection-observer')

import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router/router'
import store from './store/store'
import './assets/tailwind.css'

// Plugins
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueToast from 'vue-toast-notification'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faSearch,
  faPlay,
  faPause,
  faVolumeUp,
  faVolumeMute,
  faChevronRight,
  faMapMarkerAlt,
  faCheckCircle
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// Import one of the available themes
// import 'vue-toast-notification/dist/theme-default.css'
import 'vue-toast-notification/dist/theme-sugar.css'
import VueObserveVisibility from 'vue-observe-visibility'
import VModal from 'vue-js-modal'

let m = document.location.href.match(/\btoken=([^&]+)/)
const previewToken = m ? m[1] : ''
const requestPath = previewToken === '' ? '/api' : '/api?token=' + previewToken

// axios configuration
const axiosEntity = axios.create({
  baseURL: requestPath,
  headers: {
    Authorization: `Bearer ${process.env.VUE_APP_AUTHORIZATION_TOKEN}`
  }
})

axiosEntity.interceptors.request.use(
  function(config) {
    if (config.baseURL !== '/api') {
      // Cancel only api requests.
      return config
    }

    //  Generate cancel token source
    let source = axios.CancelToken.source()

    // Set cancel token on this request
    config.cancelToken = source.token

    // Add to vuex to make cancellation available from anywhere
    store._modules.root._children.requests.context.commit(
      'ADD_CANCEL_TOKEN',
      source
    )

    return config
  },
  function(error) {
    return Promise.reject(error)
  }
)

Vue.use(VueAxios, axiosEntity)

Vue.use(VueToast, {
  position: 'top-right',
  duration: 6000
})

Vue.use(VueObserveVisibility)

Vue.use(VModal)

library.add(
  faSearch,
  faPlay,
  faPause,
  faVolumeUp,
  faVolumeMute,
  faChevronRight,
  faMapMarkerAlt,
  faCheckCircle
)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.mixin({
  methods: {
    updatePageTitle(newTitle) {
      document.title = newTitle
    }
  }
})

Vue.filter('nl2br', function(str) {
  if (typeof str === 'undefined' || str === null) return ''
  const breakTag = '<br />'
  return (str + '').replace(
    /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
    '$1' + breakTag + '$2'
  )
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
