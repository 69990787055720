<template>
  <router-link v-if="isRouterLink" :to="{ path: link.hyperlink }">
    {{ link.linkTitle }}
  </router-link>
  <a v-else-if="!isReferenceLink" :href="link.hyperlink" target="_blank">
    {{ link.linkTitle }}
  </a>
  <a v-else :href="link.hyperlink" @click.prevent="scrollToReference">
    {{ link.linkTitle }}
  </a>
</template>

<script>
export default {
  name: 'AnchorLink',
  props: {
    link: {
      type: Object,
      required: true
    }
  },
  computed: {
    isRouterLink() {
      return !(
        /^(http(s?)):\/\//i.test(this.link.hyperlink) ||
        /^(#)/i.test(this.link.hyperlink) ||
        /^(tel):/i.test(this.link.hyperlink) ||
        /^(mailto):/i.test(this.link.hyperlink)
      )
    },
    isReferenceLink() {
      return /^(#)/i.test(this.link.hyperlink)
    }
  },
  methods: {
    scrollToReference() {
      let targetReference = this.link.hyperlink.replace('#', '')
      targetReference = document.getElementById(targetReference)

      window.scrollTo({
        top: targetReference.offsetTop,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style scoped></style>
