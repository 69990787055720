<template>
  <div
    class="hero"
    :style="
      `background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(82, 94, 110, 0.9)), url(${backgroundImageUrl});`
    "
  >
    <div class="container">
      <img
        v-if="overlayImageUrl"
        :src="overlayImageUrl"
        :alt="overlayImageTitle"
      />
      <h2
        :class="{ 'without-overlay': !overlayImageUrl }"
        v-html="hero.text"
      ></h2>
      <AnchorButtonPrimary
        v-if="hero.buttonText && hero.buttonLink"
        class="hero-button"
        :class="{ 'with-badges': this.hero.badges.length }"
        :button-text="hero.buttonText"
        :button-link="hero.buttonLink"
      />
      <HorizontalBadges
        v-if="hero.badges.length"
        class="hero-badges"
        :badges="hero.badges"
      />
    </div>
  </div>
</template>

<script>
import AnchorButtonPrimary from '@/components/ui/AnchorButtonPrimary'
import HorizontalBadges from '@/components/Badges/HorizontalBadges'

export default {
  name: 'HeroImage',
  components: { AnchorButtonPrimary, HorizontalBadges },
  props: {
    hero: {
      type: Object,
      required: true
    }
  },
  computed: {
    backgroundImageUrl() {
      return this.hero.backgroundImage[0].url
    },
    overlayImageUrl() {
      return this.hero.overlayImage.length
        ? this.hero.overlayImage[0].url
        : null
    },
    overlayImageTitle() {
      return this.hero.overlayImage.length
        ? this.hero.overlayImage[0].title
        : null
    }
  },
  mounted() {
    this.preloadImage()
  },
  methods: {
    preloadImage() {
      const preloader = document.createElement('img')

      preloader.addEventListener('load', () => {
        setTimeout(() => {
          this.$emit('loaded')
        }, 300)
      })

      preloader.src = this.backgroundImageUrl
    }
  }
}
</script>

<style lang="scss" scoped>
.hero {
  @apply bg-cover bg-no-repeat bg-center;
  min-height: 70vh;

  .container {
    @apply flex;
    @apply flex-col;
    @apply justify-center;
    @apply align-middle;

    img {
      @apply self-center;
      margin-top: 50px;
      max-width: 50%;
    }

    h2 {
      font-size: 26px;
      line-height: 26px;
      letter-spacing: 3px;
      color: #ffffff;
      text-shadow: 4px 9px 20px rgba(26, 32, 11, 0.5);
      margin-top: 23px;
      margin-bottom: 50px;

      &.without-overlay {
        margin-top: 100px;
      }
    }

    .hero-button {
      @apply self-center;
      margin-bottom: 90px;

      &.with-badges {
        margin-bottom: 30px;
      }
    }

    .hero-badges {
      width: 100%;
      margin: 30px auto 150px auto;
    }
  }

  @media screen and (min-width: 400px) {
    .container {
      h2 {
        font-size: 32px;
        line-height: 32px;
      }
    }
  }

  /** phone screen | 40% of Size */
  @media screen and (min-width: 600px) {
    .container {
      img {
        max-width: 75%;
      }

      h2 {
        font-size: 40px;
        line-height: 40px;
        letter-spacing: 6px;
        margin-top: 23px;
        margin-bottom: 70px;

        &.without-overlay {
          margin-top: 150px;
        }
      }

      .hero-button {
        @apply self-center;
        margin-bottom: 140px;

        &.with-badges {
          margin-bottom: 45px;
        }
      }
    }
  }

  /** tablet screen | 50% of Size */
  @media screen and (min-width: 900px) {
    .container {
      img {
        margin-top: 72px;
      }

      h2 {
        font-size: 55px;
        line-height: 55px;
        letter-spacing: 6px;
      }

      .hero-button {
        @apply self-center;
        margin-bottom: 166px;

        &.with-badges {
          margin-bottom: 60px;
        }
      }

      .hero-badges {
        width: 800px;
        margin: 0 auto 88px auto;
      }
    }
  }

  /** Desktop screen | 75% of Size */
  @media screen and (min-width: 1200px) {
    .container {
      img {
        margin-top: 108px;
      }

      h2 {
        font-size: 82px;
        line-height: 82px;
        letter-spacing: 10px;
        margin-top: 47px;
        margin-bottom: 141px;

        &.without-overlay {
          margin-top: 220px;
        }
      }

      .hero-button {
        @apply self-center;
        margin-bottom: 250px;

        &.with-badges {
          margin-bottom: 90px;
        }
      }

      .hero-badges {
        width: 982px;
        margin: 0 auto 131px auto;
      }
    }
  }

  /** Large screen | 100% of Size */
  @media screen and (min-width: 1500px) {
    .container {
      img {
        margin-top: 144px;
      }

      h2 {
        font-size: 110px;
        line-height: 112px;

        &.without-overlay {
          margin-top: 285px;
        }
      }

      .hero-button {
        @apply self-center;
        margin-bottom: 333px;

        &.with-badges {
          margin-bottom: 120px;
        }
      }

      .hero-badges {
        width: 1310px;
        margin: 0 auto 175px auto;
      }
    }
  }
}
</style>
