import helpers from './helpers/helpers'

export default {
  loadCalculatorPage() {
    const requestData = {
      query: `{
        entry(slug: "calculator") {
          ... on calculator_calculator_Entry {
            seo {
              title
            }
          }
        }
      }`
    }

    return helpers.makeContentRequest(requestData, 'calculator')
  }
}
