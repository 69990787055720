import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import page from './page'
import requests from './requests'

export default new Vuex.Store({
  modules: {
    page,
    requests
  }
})
