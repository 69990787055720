<template>
  <div
    ref="highlightBlock"
    :class="[
      {
        'background-image': this.realImagePosition === 'background'
      },
      `highlight-block ${realContentPosition[0]}`
    ]"
    :style="backgroundImageCss"
  >
    <div
      :class="[
        {
          'white-background-fade': highlightBlock.contentWhiteBackground,
          'dark-theme': darkTheme
        },
        `content-container flex ${realContentPosition[0]}`
      ]"
    >
      <div class="container-1/2">
        <h3
          :class="{ 'dark-theme': darkTheme }"
          v-if="highlightBlock.blockTitle"
          v-html="highlightBlock.blockTitle"
        ></h3>
        <p
          :class="{ 'dark-theme': darkTheme }"
          v-html="highlightBlock.text"
        ></p>
        <AnchorButtonPrimary
          v-if="highlightBlock.buttonText && highlightBlock.buttonLink"
          :class="[{ 'dark-theme': darkTheme }, 'button']"
          :button-text="highlightBlock.buttonText"
          :button-link="highlightBlock.buttonLink"
        />
      </div>
    </div>

    <div
      class="image-container flex"
      :class="[
        realContentPosition[1],
        { 'side-image': realImagePosition === 'side' }
      ]"
      :style="sideBackgroundImageCss"
    >
      <div class="container-1/2">
        <img
          v-if="realImagePosition === 'side'"
          :src="imageUrl"
          :alt="imageTitle"
        />
        <span v-else>&nbsp;</span>
      </div>
    </div>

    <div class="clear-both"></div>
  </div>
</template>

<script>
import AnchorButtonPrimary from '@/components/ui/AnchorButtonPrimary'

export default {
  name: 'HighlightBlock',
  components: { AnchorButtonPrimary },
  props: {
    highlightBlock: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      blockHeight: 0,
      blockHeightSetter: null,
      currentWidth: window.innerWidth
    }
  },
  computed: {
    realImagePosition() {
      return this.highlightBlock.imagePosition
    },
    imageUrl() {
      return this.highlightBlock.image.length
        ? this.highlightBlock.image[0].url
        : ''
    },
    imageTitle() {
      return this.highlightBlock.image.length
        ? this.highlightBlock.image[0].title
        : ''
    },
    backgroundImageCss() {
      if (this.realImagePosition !== 'background') return ''

      let whiteBackgroundFade = this.highlightBlock.contentWhiteBackground
      let backgroundImage = `url(${this.imageUrl})`

      if (!whiteBackgroundFade || this.currentWidth < 900)
        return `background-image: ${backgroundImage};`

      const transparentColor = this.darkTheme ? 'black' : 'white'

      if (this.realContentPosition[0] === 'right')
        return `background-image: linear-gradient(-90deg, ${transparentColor} 27%, transparent 62%), ${backgroundImage};`

      return `background-image: linear-gradient(90deg, ${transparentColor} 27%, transparent 62%), ${backgroundImage};`
    },
    sideBackgroundImageCss() {
      if (this.realImagePosition === 'sideBackground') {
        return `background-image: url(${this.imageUrl});height: ${this.blockHeight}px;`
      }
      return ''
    },
    realContentPosition() {
      return this.highlightBlock.contentPosition
        ? ['right', 'left']
        : ['left', 'right']
    },
    darkTheme() {
      return this.highlightBlock.theme === 'dark'
    }
  },
  mounted() {
    this.setBlockHeight()
    this.listenToResize()
  },
  methods: {
    setBlockHeight() {
      // Height of container without the BG image applied. Setting to 0 will give u the true height.
      this.blockHeight = 0

      clearTimeout(this.blockHeightSetter)
      this.blockHeightSetter = setTimeout(() => {
        if (this.$refs.highlightBlock) {
          this.blockHeight = this.$refs.highlightBlock.clientHeight
        }
      }, 200)
    },
    listenToResize() {
      window.addEventListener('resize', () => {
        this.setBlockHeight()
        this.currentWidth = window.innerWidth
      })
    }
  }
}
</script>

<style lang="scss">
.highlight-block {
  @apply relative flex justify-center items-center bg-cover bg-no-repeat bg-center bg-fixed flex-col-reverse;

  &.background-image {
    .content-container {
      padding-top: 90px;
    }
  }
  &:not(.background-image) {
    .content-container {
      padding-top: 50px;
    }
  }

  &.right,
  &.left {
    .image-container {
      &.side-image {
        max-width: 80%;
      }
    }
  }

  .content-container {
    @apply w-full;
    @apply h-full;
    @apply justify-center;
    @apply text-center;
    padding-bottom: 90px;
    color: #525e6e;

    &.white-background-fade {
      background-color: rgba(255, 255, 255, 0.7);

      &.dark-theme {
        background-color: rgba(0, 0, 0, 0.7);
      }
    }

    .container-1\/2 {
      max-width: 606px !important;
      margin-left: 30px;
      margin-right: 30px;
    }

    h3 {
      @apply uppercase;
      font-size: 40px;
      line-height: 40px;
      margin-bottom: 25px;

      &.dark-theme {
        color: #ffffff;
      }
    }

    p {
      @apply text-center overflow-hidden;
      font-family: 'FuturaPtBook', sans-serif !important;
      max-width: 600px;
      font-size: 22px;
      line-height: 34px;
      letter-spacing: 1px;

      &.dark-theme {
        color: #ffffff;
      }

      ul {
        list-style: none; //disc inside;
        li {
          font-family: 'FuturaPtBook', sans-serif;
          margin: 16px auto;

          strong {
            font-family: 'FuturaPtBook', sans-serif;
          }
        }
      }
    }

    .button {
      @apply inline-block;
      min-width: 50%;
      margin-top: 50px;

      &.dark-theme {
        background-color: #ffffff;
        color: #525e6e;
      }
    }
  }

  .image-container {
    @apply h-full;
    @apply w-full;
    @apply bg-cover;
    @apply bg-no-repeat;
    @apply bg-center;
    @apply justify-center;

    img {
      padding-top: 90px;
    }
  }

  @media screen and (min-width: 600px) {
    &.background-image {
      .content-container {
        padding-top: 120px;
      }
    }

    .image-container img {
      padding-top: 120px;
    }

    .content-container {
      padding-bottom: 120px;

      .container-1\/2 {
        margin-left: 60px;
        margin-right: 60px;
      }

      h3 {
        font-size: 80px;
        line-height: 83px;
        margin-bottom: 50px;
      }

      p {
        font-size: 36px;
        line-height: 48px;

        ul li {
          margin: 32px auto;
        }
      }
    }
  }

  @media screen and (min-width: 900px) {
    flex-direction: unset;

    &.right {
      @apply flex-row-reverse;
    }

    &.right,
    &.left {
      .image-container {
        padding-right: 28px;
        padding-left: 50px;

        &.side-image {
          @apply mt-0;
          @apply max-w-full;
        }
      }
    }

    .image-container img {
      padding-top: 60px;
      padding-bottom: 60px;
    }

    .content-container {
      @apply w-1/2;
      @apply text-left;
      justify-content: unset;
      padding-top: 120px !important;

      &.white-background-fade {
        background-color: inherit;

        &.dark-theme {
          background-color: inherit;
        }
      }

      &.left {
        @apply justify-end;
      }

      h3 {
        font-size: 50px;
        line-height: 50px;
        margin-bottom: 30px;
      }

      p {
        @apply text-left;
        font-size: 30px;
        line-height: 42px;
      }
    }

    .image-container {
      @apply w-1/2;
      justify-content: unset;

      &.left {
        justify-content: flex-end;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .content-container {
      padding-top: 180px;
      padding-bottom: 180px;

      h3 {
        font-size: 80px;
        line-height: 83px;
      }

      p {
        font-size: 36px;
        line-height: 48px;
      }
    }
  }
}
</style>
