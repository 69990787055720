<template>
  <modal
    :name="modalName"
    :height="height"
    :adaptive="true"
    :width="width"
    :max-width="1200"
  >
    <button class="close-modal-button" @click="$modal.hide(modalName)">
      <span></span>
      <span></span>
    </button>

    <slot />
  </modal>
</template>

<script>
export default {
  name: 'ModalWrapper',
  props: {
    modalName: {
      type: String,
      default: () => ''
    },
    height: {
      type: String,
      default: () => 'auto'
    },
    width: {
      type: String,
      default: () => '600'
    }
  }
}
</script>

<style lang="scss">
.vm--container {
  .close-modal-button {
    @apply absolute bg-white;
    width: 32px;
    height: 32px;
    left: -13px;
    top: -13px;
    border-radius: 100%;
    box-shadow: 0 0 22px rgba(60, 60, 60, 0.7);
    padding-left: 5px;

    span {
      @apply block;
      width: 21px;
      height: 2px;

      &:first-of-type {
        @apply relative;
        top: 1px;
        border-top: 2px solid #000000;
        transform: rotate(45deg);
      }

      &:nth-of-type(2) {
        @apply relative;
        top: -1px;
        border-bottom: 2px solid #000000;
        transform: rotate(-45deg);
      }
    }
  }

  .vm--overlay {
    backdrop-filter: blur(3px);
  }

  .vm--modal {
    margin-left: 14px;
    overflow: visible;
  }
}
</style>
