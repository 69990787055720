<template>
  <div
    class="hero"
    :class="{ 'player-ready': playerLoaded }"
    :style="`background-image: url(${backgroundImageUrl});`"
  >
    <vueVimeoPlayer
      ref="player"
      class="vimeo-player"
      :video-url="hero.videoId"
      :video-id="null"
      :options="vimeoOptions"
      @ready="playerReady()"
    />

    <div class="container">
      <img
        v-if="overlayImageUrl"
        :src="overlayImageUrl"
        :alt="overlayImageTitle"
      />

      <h2 v-html="hero.text"></h2>
    </div>

    <div class="player-controls">
      <font-awesome-icon
        role="button"
        :aria-label="playing ? 'pause' : 'play'"
        :icon="playing ? 'pause' : 'play'"
        @click="playing ? pause() : play()"
      />

      <font-awesome-icon
        role="button"
        :aria-label="muted ? 'mute' : 'unmute'"
        :icon="muted ? 'volume-mute' : 'volume-up'"
        @click="muted ? unmute() : mute()"
      />
    </div>
  </div>
</template>

<script>
import { vueVimeoPlayer } from 'vue-vimeo-player'
export default {
  name: 'HeroVideo',
  components: { vueVimeoPlayer },
  props: {
    hero: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      playerLoaded: false,
      playing: true,
      muted: true,
      vimeoOptions: {
        title: false,
        byline: false,
        responsive: true,
        portrait: false,
        autoplay: true,
        muted: true,
        controls: false,
        loop: true
      }
    }
  },
  computed: {
    backgroundImageUrl() {
      return this.hero.placeholderImage.length
        ? this.hero.placeholderImage[0].url
        : null
    },
    overlayImageUrl() {
      return this.hero.overlayImage.length
        ? this.hero.overlayImage[0].url
        : null
    },
    overlayImageTitle() {
      return this.hero.overlayImage.length
        ? this.hero.overlayImage[0].title
        : null
    }
  },
  mounted() {
    this.preloadImage()
  },
  methods: {
    preloadImage() {
      if (!this.backgroundImageUrl) {
        this.$emit('loaded')
        return
      }

      const preloader = document.createElement('img')

      preloader.addEventListener('load', () => {
        setTimeout(() => {
          this.$emit('loaded')
        }, 300)
      })

      preloader.src = this.backgroundImageUrl
    },
    playerReady() {
      this.$refs.player.player.on('playing', () => {
        this.playerLoaded = true
      })
    },
    play() {
      this.playing = true
      this.$refs.player.play()
    },
    pause() {
      this.playing = false
      this.$refs.player.pause()
    },
    unmute() {
      this.muted = false
      this.$refs.player.player.setVolume(0.7)
    },
    mute() {
      this.muted = true
      this.$refs.player.mute()
    }
  }
}
</script>

<style lang="scss" scoped>
.hero {
  @apply relative bg-cover bg-no-repeat bg-center;

  &.player-ready {
    .vimeo-player,
    .player-controls {
      @apply opacity-100;
    }
  }

  .vimeo-player {
    @apply w-full;
  }

  .vimeo-player,
  .player-controls {
    @apply opacity-0;
    transition: opacity 0.3s linear;
    transition-delay: 800ms;
  }

  .player-controls {
    @apply absolute left-0 bottom-0;
    font-size: 28px;
    color: #ffffff;
    filter: drop-shadow(3px 3px 4px black);

    svg {
      @apply cursor-pointer;
      margin: 7px 14px;
    }
  }

  .container {
    @apply absolute top-0 left-0 right-0 flex flex-col justify-center items-center h-full;
    img {
      max-width: 250px;
    }

    h2 {
      font-size: 26px;
      line-height: 26px;
      letter-spacing: 3px;
      color: #ffffff;
      text-shadow: 4px 9px 20px rgba(26, 32, 11, 0.5);
      margin-top: 23px;
      margin-bottom: 50px;
    }
  }

  @media screen and (min-width: 400px) {
    .container {
      h2 {
        font-size: 32px;
        line-height: 32px;
      }
    }
  }

  /** phone screen | 40% of Size */
  @media screen and (min-width: 600px) {
    .container {
      img {
        max-width: 300px;
      }

      h2 {
        font-size: 40px;
        line-height: 40px;
        letter-spacing: 6px;
      }
    }
  }

  /** tablet screen | 50% of Size */
  @media screen and (min-width: 900px) {
    .container {
      h2 {
        font-size: 55px;
        line-height: 55px;
        letter-spacing: 6px;
      }
    }
  }

  /** Desktop screen | 75% of Size */
  @media screen and (min-width: 1200px) {
    .container {
      img {
        max-width: 600px;
      }

      h2 {
        font-size: 82px;
        line-height: 82px;
        letter-spacing: 10px;
      }
    }
  }

  /** Large screen | 100% of Size */
  @media screen and (min-width: 1500px) {
    .container {
      h2 {
        font-size: 110px;
        line-height: 112px;
      }
    }
  }
}
</style>
