<template>
  <AnchorLink class="anchor-button-primary" :link="link"></AnchorLink>
</template>

<script>
import AnchorLink from '@/components/ui/AnchorLink'

export default {
  name: 'AnchorButtonPrimary',
  components: { AnchorLink },
  props: {
    buttonText: {
      type: String,
      required: true
    },
    buttonLink: {
      type: String,
      required: true
    }
  },
  computed: {
    link() {
      return {
        linkTitle: this.buttonText,
        hyperlink: this.buttonLink
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.anchor-button-primary {
  @apply text-center;
  @apply max-w-full;
  @apply text-white;
  background-color: #525e6e;
  width: 280px;
  padding-top: 14px;
  padding-bottom: 14px;
  font-size: 24px;
  line-height: 24px;
  font-family: 'FuturaPtMedium', sans-serif;
  letter-spacing: 4px;
  transition: color 0.3s linear;

  &:hover {
    color: rgba(255, 255, 255, 0.5);
  }

  /** phone screen | 40% of Size */
  @media screen and (min-width: 600px) {
    width: 485px;
    padding-top: 21px;
    padding-bottom: 21px;
    font-size: 34px;
    line-height: 34px;
  }

  /** tablet screen | 50% of Size */
  @media screen and (min-width: 900px) {
    //
  }

  /** Desktop screen | 75% of Size */
  @media screen and (min-width: 1200px) {
    width: 600px;
    padding-top: 28px;
    padding-bottom: 28px;
    font-size: 48px;
    line-height: 48px;
  }

  /** Large screen | 100% of Size */
  @media screen and (min-width: 1500px) {
    //
  }
}
</style>
