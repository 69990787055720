<template>
  <div class="badge-section-container">
    <div
      v-if="badgeSectionData.length"
      class="badge-section"
      :style="`background-image: url(${badgeSection.backgroundImage[0].url});`"
    >
      <div class="container">
        <h3>{{ badgeSection.titleText }}</h3>
        <p v-html="badgeSection.textContent"></p>
        <HorizontalBadges
          class="horizontal-badges"
          :badges="badgeSection.badges"
          :dark-badges="true"
        />
        <AnchorButtonPrimary
          v-if="badgeSection.buttonText && badgeSection.buttonLink"
          class="button"
          :button-text="badgeSection.buttonText"
          :button-link="badgeSection.buttonLink"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AnchorButtonPrimary from '@/components/ui/AnchorButtonPrimary'
import HorizontalBadges from '@/components/Badges/HorizontalBadges'
export default {
  name: 'BadgeSectionContainer',
  components: { HorizontalBadges, AnchorButtonPrimary },
  props: {
    badgeSectionData: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  computed: {
    badgeSection() {
      return this.badgeSectionData[0] || []
    }
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>
.badge-section-container {
  .badge-section {
    @apply bg-cover bg-no-repeat bg-center;

    .container {
      @apply overflow-hidden;
      padding-top: 90px;
      padding-bottom: 90px;

      h3 {
        @apply uppercase;
        font-size: 40px;
        line-height: 40px;
        margin-bottom: 25px;
      }

      p {
        @apply w-full text-center overflow-hidden mx-auto;
        font-family: 'FuturaPtBook', sans-serif;
        font-size: 22px;
        line-height: 34px;
        letter-spacing: 1px;

        ul {
          list-style: disc inside;
          li {
            font-family: FuturaPtBook, serif;
            margin: 16px auto;
          }
        }
      }

      .horizontal-badges {
        margin-top: 50px;
      }

      .button {
        @apply inline-block;
        min-width: 50%;
        margin-top: 50px;
      }
    }
  }

  @media screen and (min-width: 600px) {
    .badge-section {
      .container {
        padding-top: 120px;
        padding-bottom: 120px;

        h3 {
          font-size: 80px;
          line-height: 83px;
          margin-bottom: 50px;
        }

        p {
          @apply w-full;
          font-size: 36px;
          line-height: 48px;

          ul li {
            margin: 32px auto;
          }
        }
      }
    }
  }

  @media screen and (min-width: 900px) {
    .badge-section {
      .container {
        @apply w-4/5;
        padding-top: 120px !important;
        padding-bottom: 120px !important;

        h3 {
          font-size: 50px;
          line-height: 50px;
          margin-bottom: 30px;
        }

        p {
          @apply text-center;
          font-size: 30px;
          line-height: 42px;
        }
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .badge-section {
      .container {
        padding-top: 180px;
        padding-bottom: 180px;

        h3 {
          font-size: 80px;
          line-height: 83px;
        }

        p {
          @apply w-3/5;
          font-size: 36px;
          line-height: 48px;
        }
      }
    }
  }
}
</style>
