<template>
  <div class="horizontal-badges-container">
    <Badge
      v-for="badge in badges"
      :key="badge.id"
      :badge="badge"
      :dark-badges="darkBadges"
    />
  </div>
</template>

<script>
import Badge from '@/components/Badges/Badge'

export default {
  name: 'HorizontalBadges',
  components: { Badge },
  props: {
    badges: {
      type: Array,
      required: true
    },
    darkBadges: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.horizontal-badges-container {
  @apply flex justify-evenly flex-wrap;

  @media screen and (min-width: 900px) {
    @apply justify-evenly;
  }
}
</style>
