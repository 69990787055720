<template>
  <div id="app">
    <SiteHeader />
    <Page>
      <router-view :key="$route.fullPath" />
    </Page>
    <SiteFooter />
  </div>
</template>

<script>
// @ is an alias to /src
import Page from '@/components/ui/Page'
import SiteFooter from '@/components/Footer/SiteFooter'
import SiteHeader from '@/components/Header/SiteHeader'
import { mapActions } from 'vuex'

export default {
  name: 'App',
  components: {
    Page,
    SiteFooter,
    SiteHeader
  },
  mounted() {
    this.redirectCheck()
  },
  methods: {
    ...mapActions('page', {
      toggleShowPage: 'toggleShowPage'
    }),
    redirectCheck() {
      // Checking for .htaccess redirects that the service worker may be interfering with.
      const windowLocation = window.location.href

      if (window.location.pathname === '/') {
        return
      }

      this.axios({
        method: 'get',
        url: windowLocation,
        baseURL: windowLocation
      })
        .then(response => {
          const responseURL = response.request.responseURL
          if (windowLocation !== responseURL) {
            console.log(
              `Found 301 redirect. From: ${windowLocation}; To: ${responseURL}`
            )
            window.location.href = responseURL
          }
        })
        .catch(failure => {
          const responseURL = failure.response.request.responseURL.split('?')[0]
          if (responseURL !== windowLocation) {
            console.log(
              `Found 301 redirect. From: ${windowLocation}; To: ${responseURL}`
            )
            window.location.href = responseURL
          }
        })
    }
  }
}
</script>

<style lang="scss">
@import 'sass/app';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #525e6e;
}
</style>
