<template>
  <div class="hero-container">
    <HeroImage
      v-if="heroItem && heroItem.hasOwnProperty('backgroundImage')"
      :hero="heroItem"
      @loaded="$emit('loaded')"
    />
    <HeroVideo
      v-if="heroItem && heroItem.hasOwnProperty('videoId')"
      :hero="heroItem"
      @loaded="$emit('loaded')"
    />
  </div>
</template>

<script>
import HeroImage from '@/components/Hero/HeroImage'
import HeroVideo from '@/components/Hero/HeroVideo'

export default {
  name: 'HeroContainer',
  components: { HeroImage, HeroVideo },
  props: {
    heroData: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  computed: {
    heroItem() {
      // At this time we are not making a carousel. Just a single item for now.
      return this.heroData.length ? this.heroData[0] : {}
    }
  }
}
</script>

<style scoped></style>
