<template>
  <div
    class="highlight-document-block"
    :style="
      `background-image: linear-gradient(rgba(82, 94, 110, 0.9), rgba(82, 94, 110, 0.8)), url('${imageUrl}');`
    "
  >
    <div class="container">
      <h3 v-if="highlightBlock.blockTitle && highlightBlock.blockTitle.length">
        {{ highlightBlock.blockTitle }}
      </h3>

      <ul class="document-list">
        <li
          v-for="document in mappedDocuments"
          :key="document.id"
          class="document"
        >
          <a :href="document.documentUrl" target="_blank">
            {{ document.documentTitle }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HighlightDocumentBlock',
  props: {
    highlightBlock: {
      type: Object,
      required: true
    }
  },
  computed: {
    imageUrl() {
      return this.highlightBlock.backgroundImage.length
        ? this.highlightBlock.backgroundImage[0].url
        : ''
    },
    mappedDocuments() {
      return this.highlightBlock.documents.map(document => {
        document.documentUrl = document.document[0].document[0].url
        document.documentTitle =
          document.documentTitle && document.documentTitle.length
            ? document.documentTitle
            : document.document[0].title
        return document
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.highlight-document-block {
  @apply bg-cover bg-no-repeat bg-center bg-fixed pb-32;

  .container {
    h3 {
      @apply uppercase text-white mb-32 py-20;
      background-color: #525e6e;
      font-size: 40px;
    }

    ul {
      @apply w-full mx-auto;
      columns: 1;

      li {
        @apply text-white pb-4;
        font-size: 24px;
      }
    }
  }

  @media screen and (min-width: 600px) {
    .container {
      h3 {
        font-size: 50px;
      }

      ul {
        li {
          font-size: 32px;
        }
      }
    }
  }

  @media screen and (min-width: 900px) {
    .container {
      h3 {
        font-size: 65px;
      }

      ul {
        columns: 2;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .container {
      ul {
        @apply w-2/3;
      }
    }
  }
}
</style>
