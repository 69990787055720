<template>
  <div
    class="highlight-video-block"
    :style="`background-image: url('${imageUrl}');`"
  >
    <font-awesome-icon
      class="play-icon"
      role="button"
      aria-label="Open Video Player"
      icon="play"
      @click="$modal.show(modalName)"
    />

    <ModalWrapper class="video-modal" :modal-name="modalName" width="90%">
      <vueVimeoPlayer
        ref="player"
        class="vimeo-player"
        :video-url="highlightBlock.videoUrl"
        :video-id="null"
        :options="vimeoOptions"
      />
    </ModalWrapper>
  </div>
</template>

<script>
import ModalWrapper from '@/components/ui/ModalWrapper'
import { vueVimeoPlayer } from 'vue-vimeo-player'
export default {
  name: 'HighlightVideoBlock',
  components: { ModalWrapper, vueVimeoPlayer },
  props: {
    highlightBlock: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      vimeoOptions: {
        title: false,
        byline: false,
        responsive: true,
        portrait: false,
        autoplay: false,
        muted: false,
        loop: true
      }
    }
  },
  computed: {
    imageUrl() {
      return this.highlightBlock.backgroundImage.length
        ? this.highlightBlock.backgroundImage[0].url
        : ''
    },
    modalName() {
      return `highlight_${this.highlightBlock.id}_video`
    }
  }
}
</script>

<style lang="scss" scoped>
.highlight-video-block {
  @apply bg-cover bg-center bg-no-repeat;
  padding-top: 23%;
  padding-bottom: 23%;

  .play-icon {
    color: rgba(255, 255, 255, 0.8);
    font-size: 180px;
    transition: all 0.3s ease;

    &:hover {
      color: rgba(255, 255, 255, 0.95);
      transform: scale(1.1);
    }
  }

  .video-modal {
    .vimeo-player {
      @apply py-4 px-4;
    }
  }

  @media screen and (min-width: 600px) {
    .video-modal {
      .vimeo-player {
        @apply py-8 px-8;
      }
    }
  }
}
</style>
