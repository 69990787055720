import helpers from './helpers/helpers'

export default {
  loadHomePage() {
    const requestData = {
      query: `{
        entry(slug: "home") {
          ... on home_home_Entry {
            seo {
              title
            }
            hero {
              ... on hero_heroImage_BlockType {
                backgroundImage { 
                  url
                  title
                }
                overlayImage {
                  url
                  title
                }
                text
                buttonText
                buttonLink
                badges {
                  ... on badges_BlockType {
                    id
                    badge {
                      ... on badges_badge_Entry {
                        image {
                          url
                          title
                        }
                        documentAttachment {
                          ... on documents_document_Entry {
                            title
                            document {
                              url
                              title
                            }
                          }
                        }
                        anchorLink
                        title
                      }
                    }
                    text
                  }
                }
              }
              ... on hero_heroVideo_BlockType {
                videoId
                placeholderImage {
                  url
                }
                overlayImage {
                  url
                  title
                }
                text
              }
            }
            highlightsTower {
              ... on highlightsTower_towerBlock_BlockType {
                id
                imagePosition
                image {
                  url
                  title
                }
                contentPosition
                theme
                contentWhiteBackground
                blockTitle
                text
                buttonText
                buttonLink
              }
              ... on highlightsTower_towerVideo_BlockType {
                id
                backgroundImage {
                  url
                }
                videoUrl
              }
            }
            badgeSection {
              ... on badgeSection_badgeBlock_BlockType {
                id
                backgroundImage {
                  url
                }
                titleText
                textContent
                badges {
                  ... on badges_badge_Entry {
                    id
                    title
                    image {
                      url
                      title
                    }
                    documentAttachment {
                      ... on documents_document_Entry {
                        document {
                          url
                        }
                      }
                    }
                  }
                }
                buttonText
                buttonLink
              }
            }
          }
        }
      }`
    }

    return helpers.makeContentRequest(requestData, 'homepage')
  }
}
