import Vue from 'vue'

export default {
  makeContentRequest(requestData, storageKey) {
    return Vue.axios
      .post('', requestData)
      .then(response => response.data)
      .then(data => {
        if (this.dataHasErrors(data)) return data

        const response = data.data
        this.setPageTitle(response)
        this.storeResponse(response, storageKey)

        return response
      })
      .catch(error => {
        return this.readStoredResponse(storageKey, error.response)
      })
  },

  dataHasErrors(data) {
    const errors = data.errors || data.error

    if (errors !== undefined) {
      Vue.$toast.error('Error encountered: ' + this.extractErrorMessage(errors))
      return true
    }
    return false
  },

  setPageTitle(response) {
    if (response.entry && response.entry.seo && response.entry.seo.title) {
      let element = document.createElement('div')
      element.innerHTML = response.entry.seo.title
      document.title = element.textContent
    }
  },

  storeResponse(response, storageKey) {
    localStorage.setItem(storageKey, JSON.stringify(response))
  },

  readStoredResponse(storageKey, errorResponse = null) {
    const storedData = localStorage.getItem(storageKey)

    if (!storedData && !!errorResponse) {
      this.dataHasErrors(errorResponse.data)
    } else if (!storedData) {
      return null
    }

    return JSON.parse(storedData)
  },

  extractErrorMessage(errors) {
    // errors[0].message || errors[0] || errors
    if (typeof errors === 'string') {
      return errors
    } else if (typeof errors === 'object') {
      return errors[0].message || errors[0]
    }
  },

  slugify(string) {
    const a =
      'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
    const b =
      'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
    const p = new RegExp(a.split('').join('|'), 'g')

    return string
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, '-and-') // Replace & with 'and'
      .replace(/[^\w-]+/g, '') // Remove all non-word characters
      .replace(/--+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '') // Trim - from end of text
  }
}
