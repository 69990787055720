<template>
  <main id="page" class="page" :class="{ show: showPage }">
    <div ref="loadingOverlay" class="loading"></div>
    <slot></slot>
  </main>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Page',
  data() {
    return {
      show: false
    }
  },
  computed: {
    ...mapState('page', {
      showPage: 'showPage'
    })
  },
  watch: {
    $route() {
      if (this.showPage) {
        // Toggle to false on route change.
        this.toggleShowPage()
        this.toggleLoadingOverlay(false)
      }
    },
    showPage(newVal) {
      if (!newVal) {
        document.title = 'Loading... - Milne Fruit'
      }

      // Wait for the css animation to complete and then hide the element.
      setTimeout(() => {
        this.toggleLoadingOverlay(newVal)
      }, 600)
    }
  },
  mounted() {
    this.toggleLoadingOverlay(this.showPage)
  },
  methods: {
    ...mapActions('page', {
      toggleShowPage: 'toggleShowPage'
    }),
    toggleLoadingOverlay(hide = true) {
      if (hide) {
        this.$refs.loadingOverlay.classList.add('hidden')
        return
      }
      this.$refs.loadingOverlay.classList.remove('hidden')
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  @apply relative;
  margin-top: 104px;
  background-color: #ffffff;
  min-height: 75vh;

  .loading {
    @apply absolute;
    @apply h-full;
    @apply w-full;
    opacity: 1;
    background-color: #ffffff;
    z-index: 90;
  }

  &.show .loading {
    transition: all 0.6s linear;
    opacity: 0;
  }

  @media screen and (min-width: 900px) {
    margin-top: 164px;
  }
}
</style>
