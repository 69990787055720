import helpers from './helpers/helpers'

export default {
  loadContactPage() {
    const requestData = {
      query: `{
        entry(slug: "contact") {
          ... on contact_contact_Entry {
            seo {
              title
            }
            contactHero {
              ...on contactHero_contactSection_BlockType {
                backgroundImage {
                  url
                }
                emailAddress
                phoneNumber
                linkedinUrl
              }
            }
          }
        }
      }`
    }

    return helpers.makeContentRequest(requestData, 'contact')
  }
}
