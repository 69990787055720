<template>
  <header :class="{ 'mobile-menu-active': mobileMenuActive }">
    <div class="container">
      <div class="upper-container">
        <nav aria-label="Secondary Navigation">
          <AnchorLink
            v-for="navItem in secondaryNavItems"
            :key="navItem.id"
            :link="navItem"
            :class="{ active: currentLink === navItem.hyperlink }"
          />
        </nav>
      </div>

      <div class="lower-container">
        <div class="site-logo">
          <router-link aria-label="Back to Home" to="/">
            <img
              ref="logoImage"
              src="@/assets/nav_logo.png"
              :alt="logoImageTitle"
            />
          </router-link>
        </div>

        <button
          :aria-label="
            mobileMenuActive ? 'Close mobile menu' : 'Open mobile menu'
          "
          id="mobileMenuButton"
          :class="{ active: mobileMenuActive }"
          @click="mobileMenuActive = !mobileMenuActive"
        >
          <span class="bar" />
          <span class="bar" />
          <span class="bar" />
        </button>

        <nav aria-label="Main Navigation">
          <div
            class="nav-link"
            v-for="navItem in mainNavItems"
            :key="navItem.id"
            @mouseover="showSubnavigation(navItem)"
            @focusin="showSubnavigation(navItem)"
            @mouseout="hideSubnavigation(navItem)"
            @focusout="hideSubnavigation(navItem)"
            @click="hideSubnavigation(navItem)"
          >
            <AnchorLink
              :class="{ active: currentLink === navItem.hyperlink }"
              :link="navItem"
            />
            <div
              v-if="navItem.subNavigation.length"
              :class="[
                {
                  hidden:
                    !isSubnavHiding(navItem) &&
                    !isSubnavShowing(navItem) &&
                    activeSubnav !== navItem.id
                },
                { hiding: isSubnavHiding(navItem) },
                { showing: isSubnavShowing(navItem) },
                'subnavigation'
              ]"
            >
              <div class="arrow-up"></div>
              <AnchorLink
                v-for="subNavItem in navItem.subNavigation"
                :key="subNavItem.id"
                :class="{ active: currentLink === subNavItem.hyperlink }"
                :link="subNavItem"
              />
            </div>
          </div>

          <div class="nav-link">
            <router-link :to="{ name: 'Search' }">
              <font-awesome-icon class="search-icon" icon="search" />
              <span class="sr-only">Search</span>
            </router-link>
          </div>
        </nav>
      </div>

      <div ref="mobileMenuContainer" class="mobile-menu">
        <nav class="main-menu" aria-label="Main Navigation">
          <div
            v-for="navItem in mainNavItems"
            :key="navItem.id"
            class="nav-link"
          >
            <font-awesome-icon
              v-if="navItem.subNavigation.length"
              :class="[
                { open: mobileSubNavShowing.indexOf(navItem.id) !== -1 },
                'sub-nav-toggle'
              ]"
              role="button"
              :aria-label="
                `${
                  mobileSubNavShowing.indexOf(navItem.id) !== -1
                    ? 'open'
                    : 'close'
                } sub navigation ${navItem.linkTitle}`
              "
              :icon="'chevron-right'"
              @click="
                mobileSubNavShowing.indexOf(navItem.id) === -1
                  ? openMobileSubnav(navItem)
                  : closeMobileSubnav(navItem)
              "
            />
            <AnchorLink
              :class="{ active: currentLink === navItem.hyperlink }"
              :link="navItem"
            />

            <div
              v-if="navItem.subNavigation.length"
              :class="[
                { open: mobileSubNavShowing.indexOf(navItem.id) !== -1 },
                'sub-navigation'
              ]"
            >
              <AnchorLink
                v-for="subNavItem in navItem.subNavigation"
                :key="subNavItem.id"
                :class="{ active: currentLink === subNavItem.hyperlink }"
                :link="subNavItem"
              />
            </div>
          </div>
        </nav>

        <span class="separator" />

        <nav class="secondary-menu" aria-label="Secondary Navigation">
          <AnchorLink
            v-for="navItem in secondaryNavItems"
            :key="navItem.id"
            :class="{ active: currentLink === navItem.hyperlink }"
            :link="navItem"
          />
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
import api from '@/api'
import apiHelper from '@/api/helpers/helpers'
import AnchorLink from '@/components/ui/AnchorLink'
export default {
  name: 'SiteHeader',
  components: { AnchorLink },
  data() {
    return {
      logoImage: null,
      mainNavItems: [],
      secondaryNavItems: [],
      mobileMenuActive: false,
      activeSubnav: null,
      subnavShowing: [],
      subnavTimeouts: {},
      subnavHiding: [],
      mobileSubNavShowing: []
    }
  },
  computed: {
    logoImageTitle() {
      if (!this.logoImage) return 'Milne Fruit Logo'
      return this.logoImage.title || 'Milne Fruit Logo'
    },
    logoImageUrl() {
      if (!this.logoImage) return null
      return this.logoImage.url || null
    },
    currentLink() {
      return this.$route.path
    }
  },
  watch: {
    logoImageUrl(newVal) {
      if (newVal.length) {
        this.swapLogoImage(newVal)
      }
    },
    $route() {
      if (this.mobileMenuActive) {
        this.mobileMenuActive = false
      }
    }
  },
  mounted() {
    this.loadHeaderContent()
    this.listenToResize()
  },
  methods: {
    loadHeaderContent() {
      this.setHeaderContent(apiHelper.readStoredResponse('globals.header'))
      api.globals.loadHeader().then(response => {
        this.setHeaderContent(response)
      })
    },
    setHeaderContent(response) {
      if (!response || !response.globalSets) return

      const headerLogo =
        response.globalSets.find(
          globalSet => globalSet.handle === 'headerLogo'
        ) || null
      this.logoImage = headerLogo ? headerLogo.image[0] : headerLogo

      const mainMenu =
        response.globalSets.find(
          globalSet => globalSet.handle === 'mainMenu'
        ) || null
      this.mainNavItems = mainMenu ? mainMenu.linkList : []

      const secondaryMenu =
        response.globalSets.find(
          globalSet => globalSet.handle === 'secondaryMenu'
        ) || null
      this.secondaryNavItems = secondaryMenu ? secondaryMenu.linkList : []
    },
    swapLogoImage(newVal) {
      const imageLoader = document.createElement('img')
      imageLoader.onload = () => {
        this.$refs.logoImage.src = newVal
      }
      imageLoader.src = newVal
    },
    listenToResize() {
      this.adjustMobileNavigationVisibility()
      window.addEventListener('resize', this.adjustMobileNavigationVisibility)
    },
    adjustMobileNavigationVisibility() {
      if (window.innerWidth >= 900 && this.mobileMenuActive) {
        this.mobileMenuActive = false
      }

      if (window.innerWidth >= 900) {
        this.$refs.mobileMenuContainer.classList.add('hidden')
      } else {
        this.$refs.mobileMenuContainer.classList.remove('hidden')
      }
    },
    showSubnavigation(navItem) {
      this.activeSubnav = navItem.id
      this.stopHiding(navItem)

      clearTimeout(this.subnavTimeouts[navItem.id])

      this.subnavTimeouts[navItem.id] = setTimeout(() => {
        this.startShowing(navItem)
      }, 0)
    },
    hideSubnavigation(navItem) {
      this.startHiding(navItem)
      this.stopShowing(navItem)

      clearTimeout(this.subnavTimeouts[navItem.id])

      this.subnavTimeouts[navItem.id] = setTimeout(() => {
        this.activeSubnav = null
        this.stopHiding(navItem)
      }, 600)
    },
    stopHiding(navItem) {
      let indexOfNavItem = this.subnavHiding.indexOf(navItem.id)
      if (indexOfNavItem !== -1) {
        this.subnavHiding.splice(indexOfNavItem, 1)
      }
      // this.subnavHiding = false
    },
    startShowing(navItem) {
      if (!this.isSubnavShowing(navItem)) {
        this.subnavShowing.push(navItem.id)
      }
    },
    stopShowing(navItem) {
      const indexOfNavItem = this.subnavShowing.indexOf(navItem.id)
      if (indexOfNavItem !== -1) {
        this.subnavShowing.splice(indexOfNavItem, 1)
      }
      // this.subnavHiding = false
    },
    startHiding(navItem) {
      if (!this.isSubnavHiding(navItem)) {
        this.subnavHiding.push(navItem.id)
      }
    },
    isSubnavHiding(navItem) {
      return this.subnavHiding.indexOf(navItem.id) !== -1
    },
    isSubnavShowing(navItem) {
      return this.subnavShowing.indexOf(navItem.id) !== -1
    },
    openMobileSubnav(navItem) {
      if (this.mobileSubNavShowing.indexOf(navItem.id) !== -1) return

      this.mobileSubNavShowing.push(navItem.id)
    },
    closeMobileSubnav(navItem) {
      const itemIndex = this.mobileSubNavShowing.indexOf(navItem.id)
      if (itemIndex === -1) return

      this.mobileSubNavShowing.splice(itemIndex, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
header {
  @apply absolute;
  @apply w-full;
  @apply text-white;
  top: 0;
  background-color: #525e6e;
  box-shadow: 0 0 25px rgba(82, 94, 110, 62%);
  height: 104px;
  z-index: 100;
  transition: height 0.3s linear;

  &.mobile-menu-active {
    @apply h-screen;
    @apply fixed;
    @apply inset-0;

    .container {
      @apply h-auto;
    }

    .mobile-menu {
      @apply relative;
      height: calc(100vh - 104px);
      background-color: #525e6e;
    }
  }

  .mobile-menu {
    @apply h-0;
    @apply overflow-hidden;
    transition: height 0.3s linear;

    .main-menu {
      @apply flex;
      @apply w-full;
      @apply flex-col;
      padding: 60px 0 24px;

      .nav-link {
        .sub-nav-toggle {
          font-size: 26px;
          margin: 0 6px;
          transition: all 0.3s ease;

          &.open {
            transform: rotate(90deg);
          }
        }

        a {
          font-size: 26px;
          padding: 6px 0;
        }

        .sub-navigation {
          @apply flex flex-col overflow-hidden;
          max-height: 0;
          transform-origin: top;
          transform: scaleY(0);
          transition: all 0.3s ease-in;

          &.open {
            max-height: 500px;
            transform: scaleY(1);
          }
          a {
            font-size: 20px;
          }
        }
      }
    }

    .separator {
      @apply block;
      @apply w-full;
      @apply bg-white;
      @apply mx-auto;
      max-width: 200px;
      height: 2px;
      content: '';
    }

    .secondary-menu {
      @apply flex;
      @apply flex-col;
      font-size: 22px;
      padding-top: 24px;
    }
  }

  .container {
    @apply flex;
    @apply flex-col;
    @apply justify-between;
    @apply h-full;
    @apply overflow-visible;
    padding: 30px 42px 30px 42px;

    .upper-container {
      @apply hidden;
      @apply justify-end;

      nav {
        a {
          font-size: 20px;

          &:not(:first-of-type) {
            margin-left: 16px;
          }

          &:not(:last-of-type) {
            margin-right: 16px;
          }
        }
      }
    }

    .lower-container {
      @apply flex;
      @apply justify-between;
      @apply align-bottom;
      align-items: flex-end;

      .site-logo {
        img {
          max-width: 90px;
        }
      }

      #mobileMenuButton {
        @apply flex;
        @apply justify-between;
        @apply flex-col;
        @apply outline-none;
        width: 36px;
        height: 28px;

        &.active {
          .bar:first-of-type {
            top: 13px;
            left: -4px;
            width: 44px;
            transform: rotate(45deg);
          }

          .bar:nth-of-type(2) {
            opacity: 0;
          }

          .bar:last-of-type {
            top: -13px;
            left: -4px;
            width: 44px;
            transform: rotate(-45deg);
          }
        }

        .bar {
          @apply relative;
          @apply bg-white;
          top: 0;
          left: 0;
          opacity: 1;
          width: 36px;
          height: 2px;
          transition: all 0.5s linear;
        }
      }

      nav {
        @apply hidden;
        @apply items-end;
        margin-left: 40px;

        .nav-link {
          @apply relative;
          &:not(:first-of-type) {
            > a {
              margin-left: 33px;
            }
            .subnavigation {
              right: 0;
            }
          }

          &:not(:last-of-type) {
            > a {
              margin-right: 33px;
            }
            .subnavigation {
              right: 33px;
            }
          }

          > a {
            @apply inline-block;
          }

          a {
            @apply uppercase;
            font-size: 25px;
            line-height: 25px;
          }

          .subnavigation {
            @apply absolute;
            @apply flex;
            @apply items-end;
            @apply flex-col;
            @apply opacity-0;
            transition: opacity 0.3s linear, top 0.3s linear;
            top: 47px;

            &.hiding {
              @apply opacity-0;
              transition-delay: 0.3s;
            }

            &.showing {
              top: 67px;
              @apply opacity-100;
              //transition-delay: 0.1s;
            }

            &.hidden {
              display: none;
            }

            .arrow-up {
              margin-right: 25px;
              width: 0;
              height: 0;
              border-left: 25px solid transparent;
              border-right: 25px solid transparent;
              border-bottom: 25px solid #525e6e;
            }

            a {
              @apply flex justify-center w-full whitespace-no-wrap;
              background-color: #525e6e;
              padding: 25px 45px;
              background-repeat: no-repeat;
              background-position: 0 -100px;
              background-image: linear-gradient(transparent, transparent);
              transition: color 0.3s linear, background-position 0.3s linear;

              &:hover {
                background-position: 0 0;
                background-image: linear-gradient(
                  rgba(255, 255, 255, 0.35),
                  transparent
                );
              }
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 900px) {
    height: 164px;

    .container {
      .upper-container {
        @apply flex;

        nav {
          a {
            font-size: 16px;

            &:not(:first-of-type) {
              margin-left: 16px;
            }

            &:not(:last-of-type) {
              margin-right: 16px;
            }
          }
        }
      }

      .lower-container {
        @apply justify-start;

        #mobileMenuButton {
          @apply hidden;
        }

        nav {
          @apply flex;
          margin-left: 20px;

          .nav-link {
            &:not(:first-of-type) {
              > a {
                margin-left: 12px;
              }
              .subnavigation {
                right: 0;
              }
            }

            &:not(:last-of-type) {
              > a {
                margin-right: 12px;
              }
              .subnavigation {
                right: 12px;
              }
            }

            a {
              @apply uppercase;
              font-size: 19px;
              line-height: 19px;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .container {
      .lower-container {
        nav {
          margin-left: 30px;

          .nav-link {
            &:not(:first-of-type) {
              > a {
                margin-left: 28px;
              }
              .subnavigation {
                right: 0;
              }
            }

            &:not(:last-of-type) {
              > a {
                margin-right: 28px;
              }
              .subnavigation {
                right: 28px;
              }
            }

            a {
              @apply uppercase;
              font-size: 22px;
              line-height: 22px;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 1500px) {
    .container {
      .lower-container {
        nav {
          margin-left: 40px;

          .nav-link {
            &:not(:first-of-type) {
              > a {
                margin-left: 33px;
              }
              .subnavigation {
                right: 0;
              }
            }

            &:not(:last-of-type) {
              > a {
                margin-right: 33px;
              }
              .subnavigation {
                right: 33px;
              }
            }

            a {
              @apply uppercase;
              font-size: 25px;
              line-height: 25px;
            }
          }
        }
      }
    }
  }
}
</style>
