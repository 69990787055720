<template>
  <div :class="[{ dark: darkBadges }, 'badge']">
    <a v-if="badgeDocumentUrl" :href="badgeDocumentUrl" target="_blank">
      <img :src="badgeImageUrl" :alt="badgeImageTitle" />
      <p v-html="badge.text || badge.title"></p>
    </a>
    <a v-else-if="anchorLink" :href="anchorLink" target="_blank">
      <img :src="badgeImageUrl" :alt="badgeImageTitle" />
      <p v-html="badge.text || badge.title"></p>
    </a>
    <template v-else>
      <img :src="badgeImageUrl" :alt="badgeImageTitle" />
      <p v-html="badge.text || badge.title"></p>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Badge',
  props: {
    badge: {
      type: Object,
      required: true
    },
    darkBadges: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    badgeImageUrl() {
      if (this.badge.badge !== undefined) {
        return this.badge.badge[0].image[0].url
      }
      return this.badge.image[0].url
    },
    badgeImageTitle() {
      if (this.badge.badge !== undefined) {
        return this.badge.badge[0].image[0].title
      }
      return this.badge.image[0].title
    },
    badgeDocumentUrl() {
      if (this.badge.badge !== undefined) {
        return this.badge.badge[0].documentAttachment.length
          ? this.badge.badge[0].documentAttachment[0].document[0].url
          : null
      }

      return this.badge.documentAttachment.length
        ? this.badge.documentAttachment[0].document[0].url
        : null
    },
    anchorLink() {
      if (this.badge.badge !== undefined) {
        return this.badge.badge[0].anchorLink || null
      }
      return null
    }
  }
}
</script>

<style lang="scss" scoped>
.badge {
  @apply inline-block px-4;
  margin-bottom: 30px;

  img {
    height: 75px;
    max-width: 150px;
    margin: 0 auto 10px auto;
    filter: drop-shadow(5px 5px 10px #222);
    transition: all 0.3s ease;
  }

  &.dark {
    img {
      filter: brightness(0) invert(37%) sepia(12%) saturate(745%)
        hue-rotate(175deg) brightness(91%) contrast(87%);
    }

    p {
      color: #525e6e;
      text-shadow: none;
    }
  }

  &:hover img,
  &:hover p {
    transform: scale(1.1);
  }

  &:hover p {
    text-shadow: 4px 4px 4px #3f615f;
  }

  p {
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 2px;
    font-family: 'EBGaramondBold', serif;
    color: #ffffff;
    text-shadow: 5px 5px 10px #222;
    transition: all 0.3s ease;
  }

  @media screen and (min-width: 900px) {
    @apply w-auto;
    max-width: unset;

    img {
      height: 100px;
      max-width: none;
      margin-bottom: 15px;
    }

    p {
      font-size: 24px;
      line-height: 24px;
    }
  }

  @media screen and (min-width: 1200px) {
    img {
      height: 150px;
      margin-bottom: 20px;
    }

    p {
      font-size: 28px;
      line-height: 28px;
    }
  }

  @media screen and (min-width: 1500px) {
    img {
      height: 200px;
      margin-bottom: 25px;
    }

    p {
      font-size: 32px;
      line-height: 32px;
    }
  }
}
</style>
