<template>
  <footer>
    <div class="container">
      <div class="address" v-html="address"></div>
      <nav class="navigation" aria-label="footer navigation">
        <AnchorLink v-for="link in footerLinks" :key="link.id" :link="link" />
      </nav>
      <div class="linkedin-button">
        <script
          type="IN/FollowCompany"
          data-id="978423"
          data-counter="right"
        ></script>
      </div>
    </div>
  </footer>
</template>
<script>
import api from '@/api'
import apiHelper from '@/api/helpers/helpers'
import AnchorLink from '@/components/ui/AnchorLink'
export default {
  name: 'SiteFooter',
  components: { AnchorLink },
  data() {
    return {
      footer: null
    }
  },
  computed: {
    address() {
      return this.footer ? this.footer.plainText : ''
    },
    footerLinks() {
      return this.footer ? this.footer.linkList : []
    }
  },
  mounted() {
    this.loadLinkedinJs()
    this.loadFooterContent()
  },
  methods: {
    loadLinkedinJs() {
      const linkedinScript = document.createElement('script')
      linkedinScript.setAttribute('src', 'https://platform.linkedin.com/in.js')
      document.head.appendChild(linkedinScript)
    },
    loadFooterContent() {
      this.setFooterContent(apiHelper.readStoredResponse('globals.footer'))
      api.globals.loadFooter().then(response => {
        this.setFooterContent(response)
      })
    },
    setFooterContent(response) {
      if (!response || !response.globalSet) return

      this.footer = response.globalSet
    }
  }
}
</script>

<style lang="scss" scoped>
footer {
  @apply relative;
  @apply text-white;
  background-color: #525e6e;
  box-shadow: 0 0 25px #1c2525;
  color: #ffffff;
  padding: 50px 48px;
  margin-bottom: 0;
  z-index: 100;

  .container {
    @apply flex;
    @apply flex-col-reverse;
    @apply flex-wrap;
    @apply justify-between;
    @apply items-center;
    @apply overflow-hidden;

    .address {
      @apply inline-block;
      @apply text-left;
      padding-top: 40px;
      font-size: 26px;
      line-height: 26px;
    }

    .navigation {
      @apply flex;
      @apply flex-col;
      font-size: 25px;
      line-height: 34px;

      a {
        @apply inline-block;
        padding: 3px 0;
        &:not(:first-of-type) {
          padding-left: 15px;
        }
        &:not(:last-of-type) {
          padding-right: 15px;
        }
      }
    }

    .linkedin-button {
      @apply w-full block py-6;
    }
  }

  @media screen and (min-width: 600px) {
    padding: 38px 48px;
  }

  @media screen and (min-width: 900px) {
    padding: 50px 48px;

    .container {
      .navigation {
        @apply inline;
        line-height: 25px;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .container {
      @apply flex-row;

      .address {
        padding-top: 0;
      }
    }
  }
}
</style>
