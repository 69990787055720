<template>
  <div id="homePage">
    <HeroContainer @loaded="toggleShowPageTrue" :hero-data="heroData" />
    <HighlightsTowerContainer
      class="highlights-tower"
      :highlights-tower-data="highlightsTowerData"
    />
    <BadgeSectionContainer :badge-section-data="badgeSectionData" />
  </div>
</template>

<script>
import api from '@/api'
import HeroContainer from '@/components/Hero/HeroContainer'
import HighlightsTowerContainer from '@/components/HighlightsTower/HighlightsTowerContainer'
import viewMixin from '@/mixins/viewMixin'
import BadgeSectionContainer from '@/components/Badges/BadgeSectionContainer'
import apiHelper from '@/api/helpers/helpers'

export default {
  name: 'Home',
  components: {
    BadgeSectionContainer,
    HeroContainer,
    HighlightsTowerContainer
  },
  mixins: [viewMixin],
  data() {
    return {
      heroData: [],
      highlightsTowerData: [],
      badgeSectionData: []
    }
  },
  mounted() {
    this.loadHomepage()
  },
  methods: {
    loadHomepage() {
      this.setHomepageData(apiHelper.readStoredResponse('homepage'))

      api.home.loadHomePage().then(response => {
        this.setHomepageData(response)
      })
    },
    setHomepageData(response) {
      if (!response || !response.entry) return

      this.heroData = response.entry.hero || []
      this.highlightsTowerData = response.entry.highlightsTower || []
      this.badgeSectionData = response.entry.badgeSection || []
    }
  }
}
</script>

<style lang="scss" scoped>
#homePage {
  .highlights-tower {
    box-shadow: 0 0 25px #525e6e;
  }
}
</style>
