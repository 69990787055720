import helpers from './helpers/helpers'

export default {
  loadCertificationsPage() {
    const requestData = {
      query: `{
        entry(uri: "certifications") {
          ... on certifications_certifications_Entry {
            seo {
              title
            }
            title
            certificationsHero {
              ... on certificationsHero_hero_BlockType {
                backgroundImage {
                  url
                }
                locations {
                  ... on locationCertifications_locationCertifications_Entry {
                    id
                    title
                    uri
                    image {
                      url
                      title
                    }
                  }
                }
                partners {
                  ... on partners_BlockType {
                    logo {
                      title
                      url
                    }
                    anchorLink
                  }
                }
              }
            }
          }
        }
      }`
    }

    return helpers.makeContentRequest(requestData, 'certifications')
  },
  loadLocationCertificationsPage(slug) {
    const requestData = {
      query: `{
        entry(uri: "certifications/${slug}") {
          ... on locationCertifications_locationCertifications_Entry {
            seo {
              title
            }
            locationCertificationsHero {
              ... on locationCertificationsHero_hero_BlockType {
                backgroundImage {
                  url
                }
                titleText
                subtitle
                address
                addressLink
                description
                documentBadges {
                  ... on documents_document_Entry {
                    id
                    title
                    badgeAttachment {
                      ... on badges_badge_Entry {
                        id
                        image {
                          url
                        }
                      }
                    }
                    document {
                      url
                      title
                    }
                  }
                  ... on badges_badge_Entry {
                    title
                    image {
                      url
                    }
                  }
                }
                documentsList {
                  ... on documents_document_Entry {
                    id
                    title
                    document {
                      url
                      title
                    }
                  }
                }
              }
            }
          }
        }
      }`
    }

    return helpers.makeContentRequest(
      requestData,
      `locationCertifications.${slug}`
    )
  }
}
