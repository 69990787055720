import helpers from './helpers/helpers'

export default {
  loadFruitsAndVegetablesPage() {
    const requestData = {
      query: `{
        entry(slug: "fruits-and-vegetables") {
          ... on fruitsVegetables_fruitsVegetables_Entry {
            seo {
              title
            }
            hero {
              ... on hero_heroImage_BlockType {
                backgroundImage {
                  url
                  title
                }
                overlayImage {
                  url
                  title
                }
                text
                buttonText
                buttonLink
                badges {
                  ... on badges_BlockType {
                    id
                    badge {
                      ... on badges_badge_Entry {
                        image {
                          url
                          title
                        }
                        documentAttachment {
                          ... on documents_document_Entry {
                            title
                            document {
                              url
                              title
                            }
                          }
                        }
                        anchorLink
                        title
                      }
                    }
                    text
                  }
                }
              }
              ... on hero_heroVideo_BlockType {
                videoId
                placeholderImage {
                  url
                }
                overlayImage {
                  url
                  title
                }
                text
              }
            }
            fruitsVegetablesList {
              id
              title
            }
            highlightsTower {
              ... on highlightsTower_towerBlock_BlockType {
                id
                imagePosition
                image {
                  url
                  title
                }
                contentPosition
                theme
                contentWhiteBackground
                blockTitle
                text
                buttonText
                buttonLink
              }
              ... on highlightsTower_towerVideo_BlockType {
                id
                backgroundImage {
                  url
                }
                videoUrl
              }
            }
            backgroundImage {
              id
              url
              title
            }
          }
        }
      }`
    }

    return helpers.makeContentRequest(requestData, 'fruitsAndVegetables')
  },

  loadProductsList() {
    const requestData = {
      query: `{
        entries(type: "product") {
          ... on products_product_Entry {
            id
            title
            uri
            image {
              url
              title
            }
            productTags {
              id
              title
            }
          }
        }
      }`
    }

    return helpers.makeContentRequest(requestData, 'productList')
  },

  loadProductPage(slug) {
    const requestData = {
      query: `{
        entry(uri: "fruits-and-vegetables/${slug}") {
          ... on products_product_Entry {
            seo {
              title
            },
            productHero {
              ... on productHero_hero_BlockType {
                titleText
                description
                image {
                  url
                  title
                }
              }
            }
            productSpecificationsHeader {
              ... on productSpecificationsHeader_header_BlockType {
                videoUrl
                backgroundImage {
                  url
                }
              }
            }
            productSpecifications {
              ... on productSpecifications_specifications_BlockType {
                id
                titleText
                conventionalOrganic
                juicePuree
                frozenAseptic
                specificationSheet {
                  url
                  title
                }
                nutritionalSheet {
                  url
                  title
                }
                ingredientStatement {
                  url
                  title
                }
                countryOfOrigin {
                  url
                  title
                }
                documentBadges {
                  ... on documents_document_Entry {
                    id
                    title
                    document {
                      url
                    }
                    badgeAttachment {
                      ... on badges_badge_Entry {
                        image {
                          url
                        }
                      }
                    }
                  }
                  ... on badges_badge_Entry {
                    title
                    image {
                      url
                    }
                    documentAttachment {
                      ... on documents_document_Entry {
                        document {
                          title
                          url
                        }
                      }
                    }
                  }
                }
                sunmetProductReference
                packagingOptions {
                  ... on packagingOptions_BlockType {
                    id
                    packagingType
                    quantityDescription
                  }
                }
              }
            }
          }
        }
      }`
    }

    return helpers.makeContentRequest(requestData, `productPage.${slug}`)
  }
}
