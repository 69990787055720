import helpers from './helpers/helpers'

export default {
  loadHeader() {
    const requestData = {
      query: `{
        globalSets(handle: ["headerLogo", "mainMenu", "secondaryMenu"]) {
          handle
          ...on headerLogo_GlobalSet {
            image {
              url
              title
            }
          }
          ...on mainMenu_GlobalSet {
            linkList {
              ... on linkList_link_BlockType {
                id
                linkTitle
                hyperlink
                newTab
                subNavigation {
                  ... on subNavigation_BlockType {
                    id
                    linkTitle
                    hyperlink
                    newTab
                  }
                }
              }
            }
          }
          ... on secondaryMenu_GlobalSet {
            linkList {
              ... on linkList_link_BlockType {
                id
                linkTitle
                hyperlink
                newTab
              }
            }
          }
        }
      }`
    }

    return helpers.makeContentRequest(requestData, 'globals.header')
  },
  loadFooter() {
    const requestData = {
      query: `{
        globalSet(handle: "footer") {
          ... on footer_GlobalSet {
            plainText
            linkList {
              ... on linkList_link_BlockType {
                id
                linkTitle
                hyperlink
                newTab
              }
            }
          }
        }
      }`
    }

    return helpers.makeContentRequest(requestData, 'globals.footer')
  }
}
